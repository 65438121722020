
import axios from 'axios';
export default {
    
    async createShopInvoiceItems(params)  {
        return await axios.post(`shop_invoice_items/create` , params)
    },
    async createShopInvoiceItemsList(params)  {
        return await axios.post(`shop_invoice_items/create/list` , params)
    },
    async updateShopInvoiceItemsColumn(column , value , data)  {
        return await axios.put(`shop_invoice_items/update_list?${column}=${value}` , data)
    },
    async deleteShopInvoiceItemsList(list)  {
        return await axios.delete(`shop_invoice_items/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportShopInvoiceItems(column , value)  {
        return await axios.get(`shop_invoice_items/report?${column}=${value}`)
    },
    async getAllShopInvoiceItems()  {
        return await axios.get(`shop_invoice_items/all`)
    },
    async getOneShopInvoiceItems(shop_invoice_item_id)  {
        return await axios.get(`shop_invoice_items/all/${shop_invoice_item_id}`)
    },
    async getShopInvoiceItemsByColumn(column , value)  {
        return await axios.get(`shop_invoice_items/filter?column=${column}&value=${value}`)
    },
    async deleteShopInvoiceItems(shop_invoice_item_id)  {
        return await axios.delete(`shop_invoice_items/delete/${shop_invoice_item_id}`)
    },
    async updateShopInvoiceItems(shop_invoice_item_id , params)  {
        return await axios.put(`shop_invoice_items/update/${shop_invoice_item_id}` , params)
    }
}