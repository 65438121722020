
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">

			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-card>
						<v-card-text>
							<v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line outlined></v-text-field>

							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="shop_invoice_id">
								<template v-slot:[`item.shop_invoice_date`]="{ item }">
									<div>
										{{ new Date(item.shop_invoice_date).toISOString().split('T')[0]}} {{ new Date(item.shop_invoice_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
								<template v-slot:[`item.shop_invoice_id`]="{ item }">
									<div>
										<v-btn icon class="mx-1" @click="selectShopInvoices(item)">
											<v-icon> mdi-bag-personal </v-icon>
										</v-btn>
										<v-btn icon :to="'/shop_invoices-list/'+item.shop_invoice_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteShopInvoicesList">{{$store.getters.language.data.shop_invoices.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="bag_dialog" fullscreen>
			<v-card height="100vh">
				<v-card-title>
					<p> {{selected_shop_invoices.customer_username}}'s Bag</p>
					<v-spacer></v-spacer>
					<v-btn color="success" class="elevation-0" v-if="shop_invoice_items.length > 0 && shop_invoice_items.every(item => item.shop_invoice_item_status == 'complete' && selected_shop_invoices.shop_invoice_status == 'pending'
                        )" @click="deliverOrder(selected_shop_invoices)">
						<v-icon>mdi-bag-carry-on-check</v-icon> Order is Ready to Deliver
					</v-btn>
					<v-btn color="success" class="elevation-0" disabled v-else>
						<v-icon>mdi-bag-carry-on-check</v-icon> Order is Ready to Deliver
					</v-btn>
					<v-btn icon @click="bag_dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>

					<v-layout row wrap class="ma-4">
						<v-flex xs12 lg6 xl6 md6 sm6>
							<h4>
								{{$store.getters.language.data.shop_invoices.shop_invoice_id}} : {{selected_shop_invoices.shop_invoice_id}}
							</h4>

						</v-flex>
						<v-flex xs12 lg6 xl6 md6 sm6>

							<h4>
								{{$store.getters.language.data.shop_invoices.shop_invoice_date}} :
								{{ new Date(selected_shop_invoices.shop_invoice_date).toISOString().split('T')[0]}} {{ new Date(selected_shop_invoices.shop_invoice_date).toISOString().split('T')[1].split('.')[0]}}
							</h4>
						</v-flex>
						<v-flex xs12 lg6 xl6 md6 sm6>

							<h4>
								{{$store.getters.language.data.customers.customer_username}} : {{selected_shop_invoices.customer_username}}
							</h4>
						</v-flex>
						<v-flex xs12 lg6 xl6 md6 sm6>

							<h4>
								{{$store.getters.language.data.shop_invoices.shop_invoice_status}} :
								<span v-if="selected_shop_invoices.shop_invoice_status=='pending'" style="color:red">{{selected_shop_invoices.shop_invoice_status}}</span>
								<span v-if="selected_shop_invoices.shop_invoice_status!='pending'" style="color:green">{{selected_shop_invoices.shop_invoice_status}}</span>
							</h4>
						</v-flex>
						<v-flex xs12 lg6 xl6 md6 sm6>

							<h4>
								{{$store.getters.language.data.customers.customer_phone}} : {{selected_shop_invoices.customer_phone}}
							</h4>
						</v-flex>
						<v-flex xs12 lg6 xl6 md6 sm6>

							<h4>
								{{$store.getters.language.data.customers.customer_phone_2}} : {{selected_shop_invoices.customer_phone_2}}
							</h4>
						</v-flex>
						<v-flex xs12 lg6 xl6 md6 sm6>

							<h4>
								{{$store.getters.language.data.customers.customer_city}} : {{selected_shop_invoices.customer_city}}
							</h4>
						</v-flex>
						<v-flex xs12 lg6 xl6 md6 sm6>

							<h4>
								{{$store.getters.language.data.customers.customer_address}} : {{selected_shop_invoices.customer_address}}
							</h4>
						</v-flex>
						<!-- create a line divice-->
						<v-flex xs12 lg12 xl12 md12 sm12 class="my-5">
							<hr>
						</v-flex>

						<v-flex xs12 lg4 xl4 md4 sm4>
							<h4>{{$store.getters.language.data.shop_invoices.shop_invoice_price}} : {{(selected_shop_invoices.shop_invoice_price|| 0).toLocaleString()}}</h4>
						</v-flex>
						<v-flex xs12 lg4 xl4 md4 sm4>
							<h4>{{$store.getters.language.data.shop_invoices.shop_invoice_discount}} : {{(selected_shop_invoices.shop_invoice_discount|| 0).toLocaleString()}}</h4>
						</v-flex>
						<v-flex xs12 lg4 xl4 md4 sm4>
							<h4>{{$store.getters.language.data.shop_invoices.shop_invoice_final_price}} : {{(selected_shop_invoices.shop_invoice_final_price|| 0).toLocaleString()}}</h4>
						</v-flex>

					</v-layout>
					<!-- {{shop_invoice_items}} -->
					<!-- show the shop_invoice_items in data table  -->
					<v-data-table :headers="headersItems" :items="shop_invoice_items" class="elevation-0" item-key="shop_invoice_item_id">
						<template v-slot:[`item.shop_invoice_item_price`]="{ item }">
							<div>
								{{item.shop_invoice_item_price.toLocaleString()}}
							</div>
						</template>
						<template v-slot:[`item.shop_invoice_item_id`]="{ item }">
							<div v-if="item.shop_invoice_item_status!='complete'">
								<v-btn icon class="mx-1" @click="selectShopInvoiceItems(item)" color="teal" >
									<v-icon> mdi-package-variant-plus </v-icon>
								</v-btn>

								<!-- <v-btn icon :to="'/shop_invoice_items-list/'+item.shop_invoice_item_id" color="teal" class="mx-1">
									<v-icon> mdi-pencil-outline </v-icon>
								</v-btn> -->
							</div>
						</template>
					</v-data-table>

				</v-card-text>
				<!-- <v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="bag_dialog = false">
						{{$store.getters.language.data.shop_invoices.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteShopInvoices(selected_shop_invoices.shop_invoice_id)">
						{{$store.getters.language.data.shop_invoices.yes_btn}}
					</v-btn>
				</v-card-actions> -->
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from '../../requests/shop_invoices.request.js'
	import requestsitem from './../../requests/shop_invoice_items.request.js'

	export default {
		data() {
			return {
				shop_invoices: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				shop_invoice_items: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_shop_invoices: {
					shop_invoice_date: new Date()
				},
				bag_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_price,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_price',
					},
					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_discount,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_discount',
					},
					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_final_price,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_final_price',
					},
					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_status,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_status',
					},
					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_note,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_note',
					},
					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_date,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_date',
					},
					{
						text: this.$store.getters.language.data.customers.customer_username,
						align: 'start',
						sortable: true,
						value: 'customer_username',
					},
					{
						text: this.$store.getters.language.data.users.user_username,
						align: 'start',
						sortable: true,
						value: 'user_username',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_id',
					}
				],
				headersItems: [

					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_id,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_id',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_id,
						align: 'start',
						sortable: true,
						value: 'shop_item_id',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_quantity,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_item_quantity',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_price,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_item_price',
					},
					{
						text: this.$store.getters.language.data.shop_invoice_items.shop_invoice_item_status,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_item_status',
					},
					{
						text: this.$store.getters.language.data.users.user_id,
						align: 'start',
						sortable: true,
						value: 'user_id',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_item_id',
					}
				]
			}
		},
		computed: {
			customers() {
				return this.$store.getters.customers_list
			},
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			},
		},
		mounted() {
			this.readShopInvoices();
		},
		methods: {
			deliverOrder(shop_invoice) {
				this.loading = true
				let shop_invoice_change = Object.assign({}, shop_invoice)
				shop_invoice_change.shop_invoice_status = 'ready'
				delete shop_invoice_change.shop_invoice_date
				delete shop_invoice_change.user_username
				delete shop_invoice_change.customer_username
				delete shop_invoice_change.customer_phone
				delete shop_invoice_change.customer_address
				delete shop_invoice_change.customer_phone_2
				delete shop_invoice_change.customer_city
				delete shop_invoice_change.customer_type

				requests.updateShopInvoices(shop_invoice_change.shop_invoice_id, shop_invoice_change).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'Order Delivered',
							color: 'success'
						}
						shop_invoice.shop_invoice_status = 'ready'
						this.getShopInvoice_items(shop_invoice.shop_invoice_id)
					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
					}
				})
			},
			deleteShopInvoices(shop_invoice_id) {
				requests.deleteShopInvoices(shop_invoice_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.shop_invoice_id != shop_invoice_id
						})
						this.snackbar = {
							value: true,
							text: 'ShopInvoices Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},



			readShopInvoices() {
				this.loading = true
				requests.getAllShopInvoices().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopInvoices',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopInvoices',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			getShopInvoice_items(shop_invoice_id) {
				this.loading = true
				requestsitem.getShopInvoiceItemsByColumn('shop_invoice_id', shop_invoice_id).then(r => {
					if (r.status == 200) {
						this.shop_invoice_items = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopInvoice_items',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopInvoice_items',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectShopInvoices(i) {
				this.selected_shop_invoices = i
				this.getShopInvoice_items(i.shop_invoice_id)
				this.bag_dialog = true
			},
			selectShopInvoiceItems(i) {
				this.selected_shop_invoice_items = i
				// update status of shop_invoice_items to 1
				requestsitem.updateShopInvoiceItems(i.shop_invoice_item_id, {
					shop_invoice_id: i.shop_invoice_id,
					shop_invoice_item_price: i.shop_invoice_item_price,
					shop_invoice_item_id: i.shop_invoice_item_id,
					shop_invoice_item_quantity: i.shop_invoice_item_quantity,
					shop_item_id: i.shop_item_id,
					user_id: this.user.user_id,
					shop_invoice_item_status: 'complete'
				}).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'ShopInvoiceItems Updated',
							color: 'success'
						}
						this.getShopInvoice_items(i.shop_invoice_id)
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to update ShopInvoiceItems',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to update ShopInvoiceItems',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    