
import axios from 'axios';
export default {
    
    async createShopInvoices(params)  {
        return await axios.post(`shop_invoices/create` , params)
    },
    async createShopInvoicesList(params)  {
        return await axios.post(`shop_invoices/create/list` , params)
    },
    async updateShopInvoicesColumn(column , value , data)  {
        return await axios.put(`shop_invoices/update_list?${column}=${value}` , data)
    },
    async deleteShopInvoicesList(list)  {
        return await axios.delete(`shop_invoices/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportShopInvoices(column , value)  {
        return await axios.get(`shop_invoices/report?${column}=${value}`)
    },
    async getAllShopInvoices()  {
        return await axios.get(`shop_invoices/all`)
    },
    async getOneShopInvoices(shop_invoice_id)  {
        return await axios.get(`shop_invoices/all/${shop_invoice_id}`)
    },
    async getShopInvoicesByColumn(column , value)  {
        return await axios.get(`shop_invoices/filter?column=${column}&value=${value}`)
    },
    async deleteShopInvoices(shop_invoice_id)  {
        return await axios.delete(`shop_invoices/delete/${shop_invoice_id}`)
    },
    async updateShopInvoices(shop_invoice_id , params)  {
        return await axios.put(`shop_invoices/update/${shop_invoice_id}` , params)
    }
}